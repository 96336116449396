import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { useGetBrandListMutation, useGetBrandMutation } from '../../app/features/brand'

import '../../assets/scss/brandSSelect.scss'

import { BrandDetailDto, BrandListDto } from '../../models/brand'
import { ROUTER } from '../../router/config'
import thumb_content1 from '../../assets/images/brandSelectDetail/first_img.png'
import thumb_content2 from '../../assets/images/brandSelectDetail/second.png'
import thumb_content3 from '../../assets/images/brandSelectDetail/final.png'
import product1 from '../../assets/images/brandSelectDetail/prieclatU/product1.png'
import product2 from '../../assets/images/brandSelectDetail/prieclatU/product2.png'
import product3 from '../../assets/images/brandSelectDetail/prieclatU/product3.png'
import product4 from '../../assets/images/brandSelectDetail/prieclatU/product4.png'
import product5 from '../../assets/images/brandSelectDetail/prieclatU/product5.png'
import product6 from '../../assets/images/brandSelectDetail/prieclatU/product6.png'
import product7 from '../../assets/images/brandSelectDetail/prieclatU/product7.jpg'

const BranchSSelect1: React.FC = () => {
    const { t } = useTranslation()
    const { brand: brandName } = useParams()
    const [getBrandDetailInfo, { data: brandData, isLoading: isGettingDetail }] = useGetBrandMutation()
    const [getBrandList, { data: brandListData, isLoading: isGettingList }] = useGetBrandListMutation()
    const [tabIndex, setTabIndex] = useState(0)
    const [keyword, setKeyword] = useState('')
    const PAGE_SIZE = 25
    const dataSlide = brandListData?.data?.filter((v) => v.id != brandData?.id) ?? []

    const loading = isGettingDetail || isGettingList

    const handleSearch = () => {
        if (brandName) {
            const dto: BrandDetailDto = {
                filter: {
                    name: keyword,
                },
                page: 1,
                pageSize: PAGE_SIZE,
                brandName: brandName,
            }
            getBrandDetailInfo(dto)
        }
    }

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            handleSearch()
        }
    }

    useEffect(() => {
        if (brandName) {
            const dto: BrandDetailDto = {
                filter: {
                    name: '',
                },
                page: 1,
                pageSize: PAGE_SIZE,
                brandName: brandName,
            }
            getBrandDetailInfo(dto)
            const brandListDto: BrandListDto = {
                page: 1,
                pageSize: 999,
            }
            getBrandList(brandListDto)
        }
    }, [brandName])

    return (
        <div className='wrap-container branding-common-page'>
            <div className='page-product__breadcrumb'>
                <a href={'/'}>{t('home')}</a>
                {/* <Link to={ROUTER.BRAND_SCREEN}>{` > ${t('brandScreen.brand')}`}</Link> */}
                <span>{` > About ` + ROUTER.BRAND_S_SELECT_1}</span>
            </div>
            <div className='brand-detail-screen_main'>
                <div className='brand-detail-screen_content'>
                    <div className='brand-detail-screen_content__title mb-30'>
                        <p>{t('branchDettail1.title')}</p>
                    </div>
                    <div className='brand-intro'>
                        <p className='mb-15'>{t('branchDettail1.content1')}</p>
                        <p className='mb-30'>{t('branchDettail1.content2')}</p>
                    </div>

                    <div className='about-omi-page-content-2'>
                        <div className='row'>
                            <div className='col-xl-5 box-image'>
                                <img className='about-omi-page-content-2__thumb' src={thumb_content1} alt='thumb_content' />
                            </div>
                            <div className='col-xl-6 box-text'>
                                <div className='about-omi-page-heading'>
                                    <p className='brand-page-heading__text fw-4'>{t('branchDettail1.content3')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                            <img className='about-omi-page-content-2__thumb' src={thumb_content2} alt='thumb_content' />
                        </div>
                    <div className='brand-intro'>
                        <p className='mb-15 fw-4 mt--45'>{t('branchDettail1.content4')}</p>
                    </div>
                    <div className='about-omi-page-content-2 mb-50'>
                        <div className='image-box-final'>
                            <img className='about-omi-page-content-2__thumb' src={thumb_content3} alt='thumb_content' />
                        </div>
                    </div>
                    <div className='about-omi-page-content-2'>
                        <div className='row'>
                            <div className='col-xl-5 box-image'>
                                <img className='about-omi-page-content-2__thumb' src={product1} alt='thumb_content' />
                            </div>
                            <div className='col-xl-7 box-text-product'>
                                <div className='about-omi-page-heading'>
                                    <h4 className='mb-30 titleBrand'>{t('branchDettail1.content5')}</h4>
                                    <p className='mb-15'>{t('branchDettail1.content6')}</p>
                                    <p className='mb-15 lh-22'>{t('branchDettail1.content7')}</p>
                                    <p className='mb-15'>{t('branchDettail1.content8')}</p>
                                    <p className='lh-22'>{t('branchDettail1.content9')}</p>
                                    <p className='lh-22'>{t('branchDettail1.content10')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='about-omi-page-content-2'>
                        <div className='row'>
                            <div className='col-xl-5 box-image'>
                                <img className='about-omi-page-content-2__thumb' src={product2} alt='thumb_content' />
                            </div>
                            <div className='col-xl-7 box-text-product'>
                                <div className='about-omi-page-heading'>
                                    <h4 className='mb-30 titleBrand'>{t('branchDettail1.content5')}</h4>
                                    <p className='mb-15'>{t('branchDettail1.content6')}</p>
                                    <p className='mb-15 lh-22'>{t('branchDettail1.content7')}</p>
                                    <p className='mb-15'>{t('branchDettail1.content8')}</p>
                                    <p className='lh-22'>{t('branchDettail1.content9')}</p>
                                    <p className='lh-22'>{t('branchDettail1.content10')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='about-omi-page-content-2'>
                        <div className='row'>
                            <div className='col-xl-5 box-image'>
                                <img className='about-omi-page-content-2__thumb' src={product3} alt='thumb_content' />
                            </div>
                            <div className='col-xl-7 box-text-product'>
                                <div className='about-omi-page-heading'>
                                    <h4 className='mb-30 titleBrand'>{t('branchDettail1.content5')}</h4>
                                    <p className='mb-15'>{t('branchDettail1.content6')}</p>
                                    <p className='mb-15 lh-22'>{t('branchDettail1.content7')}</p>
                                    <p className='mb-15'>{t('branchDettail1.content8')}</p>
                                    <p className='lh-22'>{t('branchDettail1.content9')}</p>
                                    <p className='lh-22'>{t('branchDettail1.content10')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='about-omi-page-content-2'>
                        <div className='row'>
                            <div className='col-xl-5 box-image'>
                                <img className='about-omi-page-content-2__thumb' src={product4} alt='thumb_content' />
                            </div>
                            <div className='col-xl-7 box-text-product'>
                                <div className='about-omi-page-heading'>
                                    <h4 className='mb-30 titleBrand'>{t('branchDettail1.content5')}</h4>
                                    <p className='mb-15'>{t('branchDettail1.content6')}</p>
                                    <p className='mb-15 lh-22'>{t('branchDettail1.content7')}</p>
                                    <p className='mb-15'>{t('branchDettail1.content8')}</p>
                                    <p className='lh-22'>{t('branchDettail1.content9')}</p>
                                    <p className='lh-22'>{t('branchDettail1.content10')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='about-omi-page-content-2'>
                        <div className='row'>
                            <div className='col-xl-5 box-image'>
                                <img className='about-omi-page-content-2__thumb' src={product5} alt='thumb_content' />
                            </div>
                            <div className='col-xl-7 box-text-product'>
                                <div className='about-omi-page-heading'>
                                    <h4 className='mb-30 titleBrand'>{t('branchDettail1.content5')}</h4>
                                    <p className='mb-15'>{t('branchDettail1.content6')}</p>
                                    <p className='mb-15 lh-22'>{t('branchDettail1.content7')}</p>
                                    <p className='mb-15'>{t('branchDettail1.content8')}</p>
                                    <p className='lh-22'>{t('branchDettail1.content9')}</p>
                                    <p className='lh-22'>{t('branchDettail1.content10')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='about-omi-page-content-2 d-flex justify-content-center align-items-center mb-30'>
                        <img className='about-omi-page-content-2__thumb image-resize' src={product6} alt='thumb_content' />
                    </div>
                    <div className='about-omi-page-content-2 d-flex justify-content-center align-items-center mb-30'>
                        <img className='about-omi-page-content-2__thumb' src={product7} alt='thumb_content' />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default BranchSSelect1
