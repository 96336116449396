import { GoogleOAuthProvider } from '@react-oauth/google'
import { useEffect } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import Layout from './Layout'
import { getInfoUser, getTemTokenAxios } from './app/features/auth'
import { useAppDispatch, useAppSelector } from './app/hooks'
import { changeLanguage, selectLanguage } from './app/slice'
import './assets/scss/app.scss'
import './assets/scss/sidebar.scss'
import './i18n/index'
import i18n from './i18n/index'
import RenderRoute from './router/RenderRoute'
import { getToken } from './helpers/cookieHelper'
import { KEY_AUTH, languageEN, languageJA, languageVI } from './constant'
import { getTemToken } from './helpers/storage'
import ScrollToTop from './layouts/ScrollToTop'

function App() {
  const client_id = process.env.REACT_APP_GOOGLE_CLIENT_ID ?? 'GG'
  const dispatch = useAppDispatch()
  const language = useAppSelector(selectLanguage)

  useEffect(() => {
    const bodyElement = document.getElementsByTagName('body')[0]
    // const lang = localStorage.getItem('languageOmiPharma')
    if (language) {
      i18n.changeLanguage(language)
    }

    if (language === languageVI) {
      bodyElement.style.fontFamily = 'Arial,sans-serif,Helvetica'
    }
    if (language === languageJA) {
      bodyElement.style.fontFamily = 'Nunito,san-serif'
    }
    if (language === languageEN) {
      bodyElement.style.fontFamily = 'Archivo,sans-serif'
    }
  }, [language])

  useEffect(() => {
    const accessToken = getToken(KEY_AUTH)
    const tempToken = getTemToken()
    if (accessToken) {
      dispatch(getInfoUser())
    }
    if (!accessToken && !tempToken) {
      dispatch(getTemTokenAxios())
    }
    const lang =
      localStorage.getItem('languageOmiPharma') || (navigator.languages ? navigator.languages[0] : navigator.language)
    const defaultLang = lang.startsWith(languageJA) ? languageJA : languageEN
    i18n.changeLanguage(defaultLang)
    dispatch(changeLanguage(defaultLang))
  }, [])

  return (
    <GoogleOAuthProvider clientId={client_id}>
      <ScrollToTop>
        <Layout>
          <RenderRoute />
          <ToastContainer />
        </Layout>
      </ScrollToTop>
    </GoogleOAuthProvider>
  )
}

export default App
