import FastAccessCart from './components/fastAccessCart'
import FastCartModal from './components/fastCart'
import VoucherSelect from './components/voucher'
import { Footer } from './layouts/Footer'
import { Header } from './layouts/header'

type LayoutProps = {
  children: React.ReactNode
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className='main-layout'>
      <div className='main-layout__content'>
        <Header />
        {children}
        <Footer />
      </div>
      <FastCartModal />
      {/* <FastAccessCart /> */}
      <VoucherSelect />
    </div>
  )
}

export default Layout
