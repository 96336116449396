import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useGetBrandListMutation, useGetBrandMutation } from '../../app/features/brand'
import '../../assets/scss/brandSSelect.scss'
import { BrandDetailDto, BrandListDto } from '../../models/brand'
import { ROUTER } from '../../router/config'
import prieclat from '../../assets/images/brandSelectDetail/prieclat/prieclat.png'
import prieclat2 from '../../assets/images/brandSelectDetail/prieclat/prieclat2.png'
import prieclat3 from '../../assets/images/brandSelectDetail/prieclat/prieclat3.png'
import prieclat4 from '../../assets/images/brandSelectDetail/prieclat/prieclat4.png'
import prieclat5 from '../../assets/images/brandSelectDetail/prieclat/prieclat5.png'
import prieclat6 from '../../assets/images/brandSelectDetail/prieclat/prieclat6.png'
import prieclat7 from '../../assets/images/brandSelectDetail/prieclat/prieclat7.png'
import product from '../../assets/images/brandSelectDetail/prieclat/lineup/product.png'
import product1 from '../../assets/images/brandSelectDetail/prieclat/lineup/product1.png'
import product2 from '../../assets/images/brandSelectDetail/prieclat/lineup/product2.png'
import product3 from '../../assets/images/brandSelectDetail/prieclat/lineup/product3.png'
import product4 from '../../assets/images/brandSelectDetail/prieclat/lineup/product4.png'
import product5 from '../../assets/images/brandSelectDetail/prieclat/lineup/product5.png'
import product6 from '../../assets/images/brandSelectDetail/prieclat/lineup/product6.png'
import product7 from '../../assets/images/brandSelectDetail/prieclat/lineup/product7.png'
import product8 from '../../assets/images/brandSelectDetail/prieclat/lineup/product8.png'
import copyRight from '../../assets/images/brandSelectDetail/prieclat/copy-right.png'


const BranchSSelect3: React.FC = () => {
    const { t } = useTranslation()
    const { brand: brandName } = useParams()
    const [getBrandDetailInfo, { data: brandData, isLoading: isGettingDetail }] = useGetBrandMutation()
    const [getBrandList, { data: brandListData, isLoading: isGettingList }] = useGetBrandListMutation()
    const [tabIndex, setTabIndex] = useState(0)
    const [keyword, setKeyword] = useState('')
    const PAGE_SIZE = 25
    const dataSlide = brandListData?.data?.filter((v) => v.id != brandData?.id) ?? []

    const loading = isGettingDetail || isGettingList

    const handleSearch = () => {
        if (brandName) {
            const dto: BrandDetailDto = {
                filter: {
                    name: keyword,
                },
                page: 1,
                pageSize: PAGE_SIZE,
                brandName: brandName,
            }
            getBrandDetailInfo(dto)
        }
    }

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            handleSearch()
        }
    }

    useEffect(() => {
        if (brandName) {
            const dto: BrandDetailDto = {
                filter: {
                    name: '',
                },
                page: 1,
                pageSize: PAGE_SIZE,
                brandName: brandName,
            }
            getBrandDetailInfo(dto)
            const brandListDto: BrandListDto = {
                page: 1,
                pageSize: 999,
            }
            getBrandList(brandListDto)
        }
    }, [brandName])

    return (
        <div className='wrap-container branding-common-page branding-common-page2'>
            <div className='page-product__breadcrumb'>
                <a href={'/'}>{t('home')}</a>
                {/* <Link to={ROUTER.BRAND_SCREEN}>{` > ${t('brandScreen.brand')}`}</Link> */}
                <span>{` > About ` + ROUTER.BRAND_S_SELECT_4}</span>
            </div>
            <div className='brand-detail-screen_main'>
                <div className='box-image-prieclat mb-30'>
                    <img src={prieclat} alt="" />
                </div>
                <div className='brand-detail-screen_content__title mb-30'>
                    <h1>{t('brandDetail3.title')}</h1>
                </div>
                <div className='brand-detail-screen_content__title mb-30 p-45'>
                    <h2>{t('brandDetail3.content1')}</h2>
                </div>

                <div className='box-image row mb-30'>
                    <div className='box-image-item col-4'>
                        <img src={prieclat2} alt="" />
                    </div>
                    <div className='box-image-item col-8'>
                        <img src={prieclat3} alt="" />
                    </div>
                </div>

                <div className='brand-detail-screen_content__title mb-50 row'>
                    <div className='col-3'>
                        <h2>{t('brandDetail3.content2')}</h2>
                    </div>
                    <div className='col-9'>
                        <span>{t('brandDetail3.content6')}</span>
                    </div>
                </div>

                <div className='box-image-prieclat'>
                    <img src={prieclat4} alt="" />
                </div>

                <div className='brand-detail-screen_content__title mb-30'>
                    <h3>{t('brandDetail3.content3')}</h3>
                </div>

                <div className='box-image-prieclat'>
                    <img src={prieclat5} alt="" />
                </div>

                <div className='brand-detail-screen_content__title mb-30'>
                    <h3 className='copyRight'>
                        {t('brandDetail3.content4')}
                        <img src={copyRight} alt="" />
                        </h3>
                </div>


                <div className='box-image-prieclat'>
                    <img src={prieclat6} alt="" />
                </div>

                <div className='brand-detail-screen_content__title mb-30'>
                    <h3>{t('brandDetail3.content5')}</h3>
                </div>

                <div className='box-image-prieclat mb-50'>
                    <img src={prieclat7} alt="" />
                </div>

                <div className='brand-detail-screen_content__title mb-50'>
                    <h3>{t('brandDetail3.lineUpTitle')}</h3>
                </div>
                <div className='box-image-line-up mb-30'>
                    <div className='img-item mb-15'>
                        <img src={product} alt="" />
                    </div>
                    <div className='img-item mb-15'>
                        <img src={product1} alt="" />
                    </div>
                    <div className='img-item mb-15'>
                        <img src={product2} alt="" />
                    </div>
                    <div className='img-item mb-15'>
                        <img src={product3} alt="" />
                    </div>
                    <div className='img-item mb-15'>
                        <img src={product4} alt="" />
                    </div>
                    <div className='img-item mb-15'>
                        <img src={product5} alt="" />
                    </div>
                    <div className='img-item mb-15'>
                        <img src={product6} alt="" />
                    </div>
                    <div className='img-item mb-15'>
                        <img src={product7} alt="" />
                    </div>
                    <div className='img-item'>
                        <img src={product8} alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BranchSSelect3
