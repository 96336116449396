import '../../assets/scss/support.scss'
import { useTranslation } from 'react-i18next'
import { scrollToTop } from '../../helpers/ultis'
import { useEffect } from 'react'
import { useGetStoreListQuery } from '../../app/features/common'
import { Link } from 'react-router-dom'
import { ROUTER } from '../../router/config'

function FaqScreen() {
  const { t } = useTranslation()
  useEffect(() => {
    scrollToTop()
  }, [])
  const { data: listStore } = useGetStoreListQuery({})
  return (
    <div className='support-screen'>
      <div className='support-screen__breadcrumbs'>
        <Link to={ROUTER.HOME_PAGE}>{t('news.homePage')}</Link>
        <span>{' > '}{t('faq.breadcrumb')}</span>
      </div>
      <div className='support-screen__main faq'>
        <div className='support-screen__main__content'>
          <div className={'delivery-policy-screen'}>
            <div className='delivery-policy-screen__title'>
              <p>{t('faq.breadcrumb')}</p>
            </div>
            <div className='terms-of-service-screen__content'>
              <div className='terms-of-service-screen__content__title mb-30'></div>
              <div className='delivery-policy-screen__title mb-30'>
                <h4 className='box-qa'>
                  <span className='questionTitle'>{t('faq.question1')}</span>
                  <span className='contentAsw question'>{t('faq.questionTitle1')}</span>
                </h4>
              </div>
              <div className='delivery-policy-screen__title mb-30'>
                <h4 className='box-qa'>
                  <span className='answerTitle'>{t('faq.answer1')}</span>
                  <span className='contentAsw answer'>{t('faq.answerContent1')}</span>
                </h4>
              </div>
              <div className='brd-bt'></div>
              <div className='delivery-policy-screen__title mb-30'>
                <h4 className='box-qa'>
                  <span className='questionTitle'>{t('faq.question2')}</span>
                  <span className='contentAsw question'>{t('faq.questionTitle2')}</span>
                </h4>
              </div>
              <div className='delivery-policy-screen__title mb-30'>
                <h4 className='box-qa'>
                  <span className='answerTitle'>{t('faq.answer2')}</span>
                  <span className='contentAsw answer'>{t('faq.answerContent2')}</span>
                </h4>
              </div>
              <div className='brd-bt'></div>
              <div className='delivery-policy-screen__title mb-30'>
                <h4 className='box-qa'>
                  <span className='questionTitle'>{t('faq.question3')}</span>
                  <span className='contentAsw question'>{t('faq.questionTitle3')}</span>
                </h4>
              </div>
              <div className='delivery-policy-screen__title mb-30'>
                <h4 className='box-qa'>
                  <span className='answerTitle'>{t('faq.answer3')}</span>
                  <span className='contentAsw answer'>{t('faq.answerContent3')}</span>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FaqScreen
