import { useTranslation } from 'react-i18next'
// import SwitchBox from '../switchBox'
import { ReactComponent as Check } from '../../assets/icons/fontAwaresome/check.svg'
import { formatPriceVND } from '../../helpers/formatPrice'
import { CartType } from '../../models/cart'
import { useAppDispatch } from '../../app/hooks'
import { selectAllItem } from '../../app/features/cart/cart-api'
import { isEmpty } from '../../helpers/ultis'
interface props {
  onCheckout: () => void
  cartData?: CartType
  totalPrice: number
  addedPoint: number
  totalQuantity: number
  userInfo: any
}

function FooterFastCart(props: props) {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { cartData, totalPrice } = props
  const isSelectAll = cartData?.items ? cartData.items.every((i) => i.selected === 1) : false

  const handleSelectAll = () => {
    // dispatch(
    //   selectAllItem({
    //     selected: isSelectAll ? 0 : 1,
    //   }),
    // ).then((res: any) => {
    //   if (res?.payload?.code === 200) {
    //     dispatch(getCartDetail())
    //   }
    // })
  }

  return (
    <div className='modal-cart-content__footer'>
      <div className='modal-cart-content__footer__info'>
        <div className='modal-footer-info-total'>
          <div onClick={handleSelectAll} className='modal-footer-info-total__check-wrap'>
            <div
              className={
                isSelectAll
                  ? 'modal-footer-info-total__check-wrap__btn-check checked'
                  : 'modal-footer-info-total__check-wrap__btn-check'
              }
            >
              <Check />
            </div>
            <p className='modal-footer-info-total__check-wrap__btn-title'>{t('fastCart.selectAll')}</p>
          </div>
          <p className='modal-footer-info-total__text'>
            {t('fastCart.totalPayment')} <span>{formatPriceVND(totalPrice)}</span>
          </p>
        </div>
        <div className='modal-footer-info-point'>
          {!isEmpty(props.userInfo) && (
            <p className='modal-footer-info-point__text-point'>
              +{props.addedPoint} {t('fastCart.pointOmi')}
            </p>
          )}
        </div>
      </div>
      <div className='modal-cart-content__footer__btn-checkout' onClick={props.onCheckout}>
        <p>
          {t('fastCart.order')} ({props.totalQuantity})
        </p>
      </div>
    </div>
  )
}

export default FooterFastCart
