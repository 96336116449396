
import '../../assets/scss/support.scss'
import { useTranslation } from 'react-i18next'
import { scrollToTop } from '../../helpers/ultis'
import { useEffect } from 'react'
import { ROUTER } from '../../router/config'
import { Link } from 'react-router-dom'
import { useCreateContactMutation } from '../../app/features/contact'
import React, { useState } from 'react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { toastSuccessErr } from '../../helpers/toast'
import LoadingOverlay from '../../components/LoadingOverlay'
import ReCAPTCHA from 'react-google-recaptcha'


function ContactScreen() {
  const { t } = useTranslation()
    const [agree, setAgree] = useState<boolean>(false)
    const [contact, {isLoading}] = useCreateContactMutation()
    const [capValue, setCapValue] = useState<string | null>(null)
    const contactSchema = yup
        .object({
            first_name: yup.string().required(t('contact.enterFirstName')),
            last_name: yup.string().required(t('contact.enterLastName')),
            email: yup.string().required(t('contact.enterEmail')),
            message: yup.string().required(t('contact.enterMessage')),
        })
        .required()

    type FormData = yup.InferType<typeof contactSchema>
    const { 
        register, 
        handleSubmit,
        formState: { errors },
        getValues,
        setValue,
    } = useForm<FormData>({
        resolver: yupResolver(contactSchema),
    })
    const CAPTCHA_SITEKEY = process.env.REACT_APP_CAPTCHA_SITEKEY ?? '6LfMITcqAAAAAGaN41w9stBgfmcmuOzC_MGQAThz'
    const CAPTCHA_SECRETKEY = process.env.REACT_APP_CAPTCHA_SECRETKEY ?? '6LckCjcqAAAAAAqw0U13io9QBoNKuCRfdkMEedAb'


    const onSubmit = async (data: FormData) => {
      try {
          const res = (await contact({
              first_name: data.first_name,
              last_name: data.last_name,
              email: data.email,
              message: data.message,
              agree: agree,
              secretKey: CAPTCHA_SECRETKEY,
              token: capValue
          })) as any
          if(res?.data?.code === 200){
              return toastSuccessErr({ type: 'success', message: t('contact.createSuccess') })
          }
          if(res?.data?.code === 400){
            return toastSuccessErr({ type: 'error', message: res?.data?.message })
          }
          toastSuccessErr({ type: 'error', message: t('error') })
      } catch (error) {
          toastSuccessErr({ type: 'error', message: t('error') })
      }
  }
  return (
    <>
    <div className='support-screen'>
      <div className='support-screen__breadcrumbs'>
        <Link to={ROUTER.HOME_PAGE}>{t('news.homePage')}</Link>
        <span>{' > '}{t('contact.breadcrumb')}</span>
      </div>
      <div className='support-screen__main'>
        <div className='support-screen__main__content'>
          <div className={'terms-of-service-screen'}>
            <div className='terms-of-service-screen__title mb-30'>
              <p>{t('contact.title')}</p>
            </div>
            <div className='terms-of-service-screen__content'>
              <form className='form_contact mt-30' onSubmit={handleSubmit(onSubmit)}>
                <div className="row form-group mb-30">
                  <div className='col-2'>
                    <label className="col-sm-10 col-form-label"><span className='required'>{t('contact.required')}</span>{t('contact.name')}</label>
                  </div>
                  <div className="col-3">
                    <input {...register('last_name')} className="form-control input-text" placeholder={t('contact.placeholder_last_name')} />
                    {errors.last_name?.message && <p className='error-message'>{errors.last_name?.message}</p>}
                  </div>
                  <div className="col-3">
                    <input {...register('first_name')} className="form-control input-text" placeholder={t('contact.placeholder_first_name')} />
                    {errors.first_name?.message && <p className='error-message'>{errors.first_name?.message}</p>}
                  </div>
                </div>

                <div className="row form-group mb-30">
                  <div className='col-2'>
                    <label className="col-sm-10 col-form-label"><span className='required'>{t('contact.required')}</span>{t('contact.email')}</label>
                  </div>
                  <div className="col-10">
                    <input {...register('email')} className="form-control input-text" placeholder={t('contact.placeholder_email')}/>
                    {errors.email?.message && <p className='error-message'>{errors.email?.message}</p>}
                  </div>
                </div>

                <div className="row form-group mb-30">
                  <div className='col-2'>
                    <label className="col-sm-10 col-form-label"><span className='required'>{t('contact.required')}</span>{t('contact.message')}</label>
                  </div>

                  <div className="col-10">
                    <textarea {...register('message')} rows={3} className="form-control input-text" placeholder={t('contact.placeholder_message')} />
                    {errors.message?.message && <p className='error-message'>{errors.message?.message}</p>}
                  </div>
                </div>

                <div className="row form-group mb-30">
                  <div className='col-2'></div>
                  <div className='col-10 clause'>
                    <p>{t('contact.clause')}</p>
                  </div>
                </div>

                <div className="row form-group mb-15">
                  <div className='col-2'></div>
                  <div className='col-10'>
                  <div className="form-check">
                    <input onClick={() => setAgree(!agree)} className="form-check-input input-check" type="checkbox" />
                    <label className="form-check-label" >
                    {t('contact.agree')}
                    </label>
                  </div>
                  </div>
                </div>
                <div className="row form-group">
                  <div className='col-2'></div>
                  <div className='col-10'>
                  <ReCAPTCHA
                            sitekey={CAPTCHA_SITEKEY}
                            onChange={(token) => setCapValue(token)}
                        />
                    <button disabled={!capValue} className='btn-send-request mt-2'>{t('contact.button')}</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LoadingOverlay isLoading={isLoading} />
    </>
  )
}

export default ContactScreen
