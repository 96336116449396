import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import translationsJP from '../locales/jp/transaltion.json'
import translationsVN from '../locales/vi/transaltion.json'
import translationsEN from '../locales/en/transaltion.json'
import i18next from 'i18next'

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: 'vi',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      jp: {
        translation: translationsJP,
      },
      vi: {
        translation: translationsVN,
      },
      en: {
        translation: translationsEN,
      }
    },
  })

export default i18n

export const getLang = () => {
  return i18next.language
}
