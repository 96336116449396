import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ic_delete from '../../../assets/icons/DetailProductScreen/ic_delete_outline.svg'
import ic_regular from '../../../assets/icons/DetailProductScreen/ic_regular.svg'
import { formatPriceVND } from '../../../helpers/formatPrice'
import { useAppDispatch } from '../../../app/hooks'
import { deleteItem } from '../../../app/features/cart/cart-api'
interface props {
  showFull: boolean
  item: any
}
function FastCartItem(props: props) {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { item } = props
  const [activeChange, setActiveChange] = useState(false)
  const onPressChange = () => {
    if (!props.showFull) return
    if (activeChange) {
      setActiveChange(true)
    }
  }
  const handleDeleteItem = () => {
    // dispatch(deleteItem(item.cart_item_id)).then((res: any) => {
    //   if (res?.payload?.code === 200) {
    //     dispatch(getCartDetail())
    //   }
    // })
  }

  const classActive = activeChange
    ? 'fast-cart-item__image-box__quantity-wrap active'
    : 'fast-cart-item__image-box__quantity-wrap'

  return (
    <div className='fast-cart-item'>
      <div className='fast-cart-item__image-box'>
        <div className='fast-cart-item__image-box__image'>
          <div className='fast-cart-item-img'>
            <img src={item?.images?.[0]} alt='thumb-product' />
          </div>
        </div>
        {item?.allow_frequency === 1 && (
          <img src={ic_regular} className='fast-cart-item__image-box__regular-icon' alt='ic-regular' />
        )}
        <img
          onClick={handleDeleteItem}
          src={ic_delete}
          className='fast-cart-item__image-box__delete-btn'
          alt='ic-delete'
        />
        <div className={classActive} onClick={onPressChange}>
          <input type={'number'} value={item?.quantity || 0} readOnly={true} />
        </div>
      </div>
      {props.showFull ? (
        <div className='fast-cart-item__content-box'>
          <div className='fast-cart-item__content-box__name'>{item.name}</div>
          <p className='fast-cart-item__content-box__price'>{formatPriceVND(319000)}</p>
          <button className='fast-cart-item__content-box__button'>{t('fastCart.buyLater')}</button>
        </div>
      ) : null}
    </div>
  )
}

export default FastCartItem
