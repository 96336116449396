import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './rootReducer'
import commonApi from './features/common'
import searchDugApi from './features/searchDrug'
import prescriptionApi from './features/prescription'
import postApi from './features/post'
import homeSearchApi from './features/homeSearch'
import brandApi from './features/brand'
import productListApi from './features/productList'
import homeApi from './features/home'
import productDetailRTKQRApi from './features/productDetail'
import categoryApi from './features/category'
// import voucherApi from './features/voucher'
import pointApi from './features/point'
import orderApi from './features/order'
import userAddressApi from './features/userAddress'
import userNotifyApi from './features/userNotify'
import settingApi from './features/setting'
import commentApi from './features/comment'
import regularScheduleApi from './features/regularSchedule'
import bankApi from './features/bank'
import userReviewApi from './features/userReview'
import userFavoriteApi from './features/userProduct/userProductFavorite'
import userSeenListApi from './features/userProduct/userProductSeen'
import userBuyWithDealListApi from './features/userProduct/buyWithDeal'
import popupApi from './features/popup'
import contactApi from './features/contact'

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(
      commonApi.middleware,
      searchDugApi.middleware,
      prescriptionApi.middleware,
      postApi.middleware,
      homeSearchApi.middleware,
      brandApi.middleware,
      productListApi.middleware,
      homeApi.middleware,
      productDetailRTKQRApi.middleware,
      categoryApi.middleware,
      // voucherApi.middleware,
      pointApi.middleware,
      orderApi.middleware,
      userAddressApi.middleware,
      userNotifyApi.middleware,
      settingApi.middleware,
      commentApi.middleware,
      regularScheduleApi.middleware,
      bankApi.middleware,
      userReviewApi.middleware,
      userFavoriteApi.middleware,
      userSeenListApi.middleware,
      userBuyWithDealListApi.middleware,
      popupApi.middleware,
      contactApi.middleware
    ),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// export type AppState = ReturnType<typeof rootReducer>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
