import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import '../../assets/scss/brandSSelect.scss'
import { ROUTER } from '../../router/config'
import thumb_content2 from '../../assets/images/brandSelectDetail/brand-2.png'
import thumb_content3 from '../../assets/images/brandSelectDetail/branch-3.png'

import lineup1 from '../../assets/images/brandSelectDetail/lineup/lineup1.png'
import lineup2 from '../../assets/images/brandSelectDetail/lineup/lineup2.png'

const BranchSSelect2: React.FC = () => {
    const { t } = useTranslation()
    return (
        <div className='wrap-container branding-common-page container-brand'>
            <div className='page-product__breadcrumb'>
                <a href={'/'}>{t('home')}</a>
                {/* <Link to={ROUTER.BRAND_SCREEN}>{` > ${t('brandScreen.brand')}`}</Link> */}
                <span>{` > About ` +  ROUTER.BRAND_S_SELECT_2}</span>
            </div>

            <div className='brand-detail-screen_main'>
                <div className='brand-detail-screen_content'>
                    <div className='brand-detail-screen_content__title mb-30'>
                        <h4>{t('brandDetail2.title')}</h4>
                    </div>
                    <div className='brand-detail-screen_content__title mb-30'>
                        <h5>{t('brandDetail2.title')}</h5>
                    </div>

                    <div className='about-omi-page-content-2'>
                        {/* <div className='row'> */}
                        <img className='about-omi-page-content-2__thumb' src={thumb_content2} alt='thumb_content' />
                        {/* </div> */}
                    </div>

                    <div className='brand-intro mb-15'>
                        <p>{t('brandDetail2.content1')}</p>
                    </div>

                    <div className='brand-detail-screen_content__title mb-30'>
                        <h4>{t('brandDetail2.content2')}</h4>
                    </div>

                    <div className='brand-intro mb-50'>
                        <p>{t('brandDetail2.content3')}</p>
                    </div>

                    <div className='private-brand mb-50'>
                        <span className='private-text'>{t('brandDetail2.subTitle')}</span>
                    </div>

                    <div className='brand-detail-screen_content__title mb-30'>
                        <h4>{t('brandDetail2.content4')}</h4>
                    </div>

                    <div className='brand-intro mb-40'>
                        <p>{t('brandDetail2.content5')}</p>
                    </div>

                    <div className='about-omi-page-content-2 mt-20 mb-50'>
                        {/* <div className='image-box-final'> */}
                        <img className='about-omi-page-content-2__thumb' src={thumb_content3} alt='thumb_content' />
                        {/* </div> */}
                    </div>

                    <div className='brand-detail-screen_content__title mb-50'>
                        <h4>{t('brandDetail2.content6')}</h4>
                    </div>

                    <div className='brand-intro mb-50'>
                        <p>{t('brandDetail2.content7')}</p>
                    </div>

                    <div className='private-brand'>
                        <span className='private-text'>{t('brandDetail2.subTitle1')}</span>
                    </div>
                    <div className='line-up-box'>
                        <div className='line-up-item mb-50'>
                            <div className='line-up-image'>
                                <img className='about-omi-page-content-2__thumb' src={lineup1} alt='thumb_content' />
                            </div>
                            <div className='line-up-content'>
                                <h4 className='mb-30'>{t('brandDetail2.lineupTitle')}</h4>
                                <p className='mb-15'>{t('brandDetail2.lineUpContent1')}</p>
                                <p className='mb-15'>{t('brandDetail2.lineUpContent2')}</p>
                                <p>{t('brandDetail2.lineUpContent3')}</p>
                            </div>
                        </div>
                        <div className='line-up-item'>
                            <div className='line-up-image'>
                                <img className='about-omi-page-content-2__thumb' src={lineup2} alt='thumb_content' />
                            </div>
                            <div className='line-up-content'>
                                <h4 className='mb-30 cl-black'>{t('brandDetail2.lineupTitle1')}</h4>
                                <p className='mb-15'>{t('brandDetail2.lineUpContent4')}</p>
                                <p className='mb-15'>{t('brandDetail2.lineUpContent5')}</p>
                                <p className='mb-15'>{t('brandDetail2.lineUpContent6')}</p>
                                <p className=''>{t('brandDetail2.lineUpContent7')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BranchSSelect2
