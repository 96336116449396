import axios from 'axios'
import queryString from 'query-string'
import { getLang } from '../i18n'
import Network from './Network'
import { getToken, removeToken } from '../helpers/cookieHelper'
import { KEY_AUTH } from '../constant'
import { getTemToken } from '../helpers/storage'
const axiosClientAdmin = axios.create({
  baseURL: Network.API_ROOT,
  headers: {
    'content-type': 'application/json',
  },
  responseType: 'json',
  paramsSerializer: {
    serialize: (params) => {
      return queryString.stringify(params, { arrayFormat: 'bracket' })
    },
  },
})

axiosClientAdmin.interceptors.request.use(
  async (config: any) => {
    const accessToken = getToken(KEY_AUTH)
    const temToken = getTemToken()
    config.headers.Language = getLang()
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`
    }
    if (!accessToken && temToken) {
      config.headers['Temp-Customer-Token'] = temToken
    }
    return config
  },
  function error() {
    return Promise.reject(error)
  },
)

axiosClientAdmin.interceptors.response.use(
  (response) => {
    if (response && response.data) {
      return response.data
    }
    return response
  },
  (error: any) => {
    if (error?.message === 'Request failed with status code 401') {
      removeToken(KEY_AUTH)
    }

    throw error
  },
)

export default axiosClientAdmin
